import {
  getWeek,
  startOfWeek,
  endOfWeek,
  addDays,
  subDays,
  Day,
  setWeek,
  getWeekYear,
} from "date-fns";
import { WeekStartDay } from "@/types/common";

// Create a mapping from WeekStartDay to date-fns weekStartsOn values
const weekStartDayMap: Record<WeekStartDay, Day> = {
  SUNDAY: 0 as Day,
  MONDAY: 1 as Day,
  SATURDAY: 6 as Day,
};

export default class WeekOfYear {
  weekStartDay: WeekStartDay;
  weekNumber: number;
  weekBaseYear: number;
  weekStarts: Date;
  weekEnds: Date;
  days: Date[];

  constructor(date: Date, weekStartDay: WeekStartDay) {
    this.weekStartDay = weekStartDay;
    this.weekNumber = this.getWeekNumber(date, weekStartDay);
    this.weekBaseYear = this.getWeekYear(date, weekStartDay);
    this.weekStarts = this.getWeekStartDate(date, weekStartDay);
    this.weekEnds = this.getWeekEndDate(date, weekStartDay);
    this.days = this.getDaysOfWeek(this.weekStarts);
  }

  private getWeekNumber(date: Date, weekStartDay: WeekStartDay): number {
    return getWeek(date, { weekStartsOn: weekStartDayMap[weekStartDay] });
  }

  private getWeekYear(date: Date, weekStartDay: WeekStartDay): number {
    return getWeekYear(date, { weekStartsOn: weekStartDayMap[weekStartDay] });
  }

  private getWeekStartDate(date: Date, weekStartDay: WeekStartDay): Date {
    return startOfWeek(date, { weekStartsOn: weekStartDayMap[weekStartDay] });
  }

  private getWeekEndDate(date: Date, weekStartDay: WeekStartDay): Date {
    return endOfWeek(date, { weekStartsOn: weekStartDayMap[weekStartDay] });
  }

  getDaysOfWeek(startDate: Date): Date[] {
    const days = [];
    for (let i = 0; i < 7; i++) {
      days.push(addDays(startDate, i));
    }
    return days;
  }

  containsDate(date: Date): boolean {
    return date >= this.weekStarts && date <= this.weekEnds;
  }

  isWithinWeeksPeriod(
    startWeek: WeekOfYear | null,
    endWeek: WeekOfYear | null,
  ): boolean {
    if (startWeek != null && this.isBefore(startWeek)) {
      return false;
    }
    if (endWeek != null && this.isAfter(endWeek)) {
      return false;
    }
    return true;
  }

  isSameWeek(other: WeekOfYear): boolean {
    return (
      this.weekNumber === other.weekNumber &&
      this.weekBaseYear === other.weekBaseYear
    );
  }

  isBefore(other: WeekOfYear): boolean {
    if (this.weekBaseYear < other.weekBaseYear) {
      return true;
    }
    if (
      this.weekBaseYear === other.weekBaseYear &&
      this.weekNumber < other.weekNumber
    ) {
      return true;
    }
    return false;
  }

  isAfter(other: WeekOfYear): boolean {
    if (this.weekBaseYear > other.weekBaseYear) {
      return true;
    }
    if (
      this.weekBaseYear === other.weekBaseYear &&
      this.weekNumber > other.weekNumber
    ) {
      return true;
    }
    return false;
  }

  plusWeeks(weeks: number): WeekOfYear {
    const newDate = addDays(this.weekStarts, weeks * 7);
    return new WeekOfYear(newDate, this.weekStartDay);
  }

  minusWeeks(weeks: number): WeekOfYear {
    const newDate = subDays(this.weekStarts, weeks * 7);
    return new WeekOfYear(newDate, this.weekStartDay);
  }

  /**
   * Get the weekId string for this week, e.g. "2022-W01"
   */
  getWeekId(): string {
    return `${this.weekBaseYear}-W${this.weekNumber.toString().padStart(2, "0")}`;
  }

  /**
   * Create a WeekOfYear instance from a weekId string, e.g. "2022-W01"
   */
  static fromWeekId(weekId: string, weekStartDay: WeekStartDay): WeekOfYear {
    const [yearString, weekNumberString] = weekId.split("-W");
    const year = parseInt(yearString, 10);
    const weekNumber = parseInt(weekNumberString, 10);
    // Create a date object set to January 1st of the given year
    const januaryFirst = new Date(year, 0, 1);
    const weekDate = setWeek(januaryFirst, weekNumber, {
      weekStartsOn: weekStartDayMap[weekStartDay],
    });
    return new WeekOfYear(weekDate, weekStartDay);
  }
}
