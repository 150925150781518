import { defineStore } from "pinia";
import { User, PaymentSystem } from "./user";
import {
  OnboardingStep,
  ThemeMode,
  WeekStartDay,
  AppLanguage,
} from "@/types/common";
import errorCodes from "@/constants/errorCodes";
import { Capacitor, CapacitorCookies } from "@capacitor/core";
import * as userApi from "@/api/userApi";
import * as userSettingsApi from "@/api/userSettingsApi";
import * as authApi from "@/api/authApi";
import { MgwApiError } from "@/api/apiExceptions";
import useInitializableStore from "@/store/initializableStore";
import { computed, ref, onMounted, onUnmounted } from "vue";
import { usePrimeVue } from "primevue/config";
import { useI18n } from "vue-i18n";
import { SafeArea } from "@capacitor-community/safe-area";
import { useRoute } from "vue-router";
import { parseISO } from "date-fns";
import { useAreasStore } from "../areas/areasStore";

export const useAuthStore = defineStore("auth", () => {
  const primeVue = usePrimeVue();
  const i18n = useI18n();
  const route = useRoute();

  const { initializationFailed, initialized, loading, baseInitialize } =
    useInitializableStore();

  const isAuthenticated = ref<boolean>(false);

  const isDarkTheme = ref<boolean>(
    window.matchMedia("(prefers-color-scheme: dark)").matches,
  );

  const defaultUser: User = {
    uid: "00000000-0000-0000-0000-000000000000",
    name: "anonymous",
    subscription: {
      type: "FREE",
      paymentSystem: "NONE",
    },
  } as User;
  const user = ref<User>(defaultUser);

  async function initialize(force = false): Promise<void> {
    return baseInitialize(internalInit, force);
  }

  async function internalInit() {
    try {
      const { isAuthenticated } = await authApi.getAuthStatus();
      if (isAuthenticated) {
        const response = await userApi.getUser();
        await setAuthenticatedUser({
          ...response.user,
          onboardingCompleted: response.user.onboardingCompleted.map(
            (it) => it as OnboardingStep,
          ),
          createdAt: parseISO(response.user.createdAt),
        } as User);
        updateTheme();
        const areasStore = useAreasStore();
        await areasStore.initialize();
      } else {
        updateUserLocale();
        updateTheme();
      }
    } catch (error) {
      console.error("Error initializing user", error);
      if (
        error instanceof MgwApiError &&
        error.code === errorCodes.AUTHENTICATION_USER_NOT_FOUND
      ) {
        await logout();
      }
      setNotAuthenticatedUser();
    }
  }

  onMounted(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.addEventListener("change", updateTheme);
  });

  onUnmounted(() => {
    const matchMedia = window.matchMedia("(prefers-color-scheme: dark)");
    matchMedia.removeEventListener("change", updateTheme);
  });

  const updateTheme = () => {
    const onlyLightTheme = route.meta.onlyLightTheme === true;

    let themeMode: ThemeMode = "SYSTEM";
    if (isAuthenticated.value) {
      themeMode = user.value.settings.themeMode;
    }

    if (themeMode === "SYSTEM") {
      isDarkTheme.value = window.matchMedia(
        "(prefers-color-scheme: dark)",
      ).matches;
    } else if (themeMode === "DARK") {
      isDarkTheme.value = true;
    } else {
      isDarkTheme.value = false;
    }

    const htmlElement = document.querySelector("html");
    if (!htmlElement) {
      return;
    }
    if (isDarkTheme.value && !onlyLightTheme) {
      htmlElement.classList.add("dark");
    } else {
      htmlElement.classList.remove("dark");
    }

    if (Capacitor.isNativePlatform()) {
      SafeArea.enable({
        config: {
          customColorsForSystemBars: true,
          statusBarColor: "#00000000", // transparent
          statusBarContent: isDarkTheme.value ? "light" : "dark",
          navigationBarColor: "#00000000", // transparent
          navigationBarContent: isDarkTheme.value ? "light" : "dark",
        },
      });
    }
  };

  async function setAuthenticatedUser(newUser: User) {
    isAuthenticated.value = true;
    user.value = newUser;
    updateUserLocale();
  }

  async function setNotAuthenticatedUser() {
    isAuthenticated.value = false;
  }

  async function logout() {
    await authApi.logout();
    if (Capacitor.isNativePlatform()) {
      // iOS, android do not support cookie deletion with domain set up
      // so we need to clear the cookie manually
      await CapacitorCookies.clearAllCookies();
    }

    // reset auth store
    setNotAuthenticatedUser();
    initialized.value = false;
    user.value = defaultUser;

    return;
  }

  async function setUserName(name: string) {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.name === name) return;

    await userApi.updateUser({ name });

    user.value.name = name;
  }

  async function saveUserLanguage(language: AppLanguage) {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.settings.language === language) return;

    await userSettingsApi.updateUserSettings({ language });

    await initialize(true); // need to reinitialize to update the areas as well
    updateUserLocale();
  }

  async function saveUserWeekStartDay(weekStartDay: WeekStartDay) {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.settings.weekStartDay === weekStartDay) return;

    userSettingsApi.updateUserSettings({ weekStartDay });

    user.value.settings.weekStartDay = weekStartDay;
    updateUserLocale();
  }

  async function saveUserTimezone(timezone: string) {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.settings.timezone === timezone) return;

    userSettingsApi.updateUserSettings({ timezone });

    user.value.settings.timezone = timezone;
  }

  async function saveThemeMode(themeMode: ThemeMode) {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.settings.themeMode === themeMode) return;

    userSettingsApi.updateUserSettings({ themeMode });

    user.value.settings.themeMode = themeMode;
    updateTheme();
  }

  async function saveUserAllowWeeklyReviewEmails(
    allowWeeklyReviewEmails: boolean,
  ) {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.settings.allowWeeklyReviewEmails === allowWeeklyReviewEmails)
      return;

    userSettingsApi.updateUserSettings({ allowWeeklyReviewEmails });

    user.value.settings.allowWeeklyReviewEmails = allowWeeklyReviewEmails;
  }

  async function saveUserWeeklyReviewEmailTime(
    weeklyReviewEmailTime: string,
  ): Promise<boolean> {
    if (!isAuthenticated.value || !user.value)
      throw new Error("User not initialized");
    if (user.value.settings.weeklyReviewEmailTime === weeklyReviewEmailTime)
      return false;

    userSettingsApi.updateUserSettings({ weeklyReviewEmailTime });

    user.value.settings.weeklyReviewEmailTime = weeklyReviewEmailTime;
    return true;
  }

  function setUserSubscriptionPremium(paymentSystem: PaymentSystem) {
    if (!isAuthenticated.value || !user.value) return;
    user.value.subscription.type = "PREMIUM";
    user.value.subscription.paymentSystem = paymentSystem;
  }

  const userOnboardingSteps = computed<OnboardingStep[]>(() => {
    if (!isAuthenticated.value || !user.value) return [];
    return user.value.onboardingCompleted;
  });

  async function completeOnboardingStep(step: OnboardingStep) {
    if (!isAuthenticated.value || !user.value) return;
    if (user.value.onboardingCompleted.includes(step)) return;

    await userApi.completeOnboardingStep(step);
    user.value.onboardingCompleted.push(step);
  }

  function updateUserLocale() {
    let language: AppLanguage;
    let weekStartDay: WeekStartDay;
    if (user.value.name === "anonymous") {
      const locale = navigator.language.split("-")[0];
      if (locale.toLowerCase() === "es") {
        language = "ES";
      } else if (locale.toLowerCase() === "en") {
        language = "EN";
      } else if (locale.toLowerCase() === "ja") {
        language = "JA";
      } else {
        language = "EN";
      }
      weekStartDay = "MONDAY";
    } else {
      language = user.value.settings.language;
      weekStartDay = user.value.settings.weekStartDay;
    }
    switch (language) {
      case "EN":
        i18n.locale.value = "en";
        break;
      case "ES":
        i18n.locale.value = "es";
        break;
      case "JA":
        i18n.locale.value = "ja";
        break;
      default:
        i18n.locale.value = "en";
    }
    if (primeVue.config.locale) {
      setPrimeVueStrings();
      switch (weekStartDay) {
        case "SUNDAY":
          primeVue.config.locale.firstDayOfWeek = 0;
          break;
        case "MONDAY":
          primeVue.config.locale.firstDayOfWeek = 1;
          break;
        case "SATURDAY":
          primeVue.config.locale.firstDayOfWeek = 6;
          break;
      }
    }
  }

  function setPrimeVueStrings() {
    if (primeVue.config.locale) {
      primeVue.config.locale.weekHeader = i18n.t("common.time.weekHeader");
      primeVue.config.locale.monthNames = i18n
        .t("common.time.monthNames")
        .split(",");
      primeVue.config.locale.monthNamesShort = i18n
        .t("common.time.monthNamesShort")
        .split(",");
      primeVue.config.locale.dayNames = i18n
        .t("common.time.dayNames")
        .split(",");
      primeVue.config.locale.dayNamesShort = i18n
        .t("common.time.dayNamesShort")
        .split(",");
      primeVue.config.locale.dayNamesMin = i18n
        .t("common.time.dayNamesMin")
        .split(",");
      primeVue.config.locale.today = i18n.t("common.time.today");
      primeVue.config.locale.clear = i18n.t("common.clear");
    }
  }

  return {
    initializationFailed,
    initialized,
    loading,
    isAuthenticated,
    user,
    initialize,
    logout,
    setUserName,
    saveUserLanguage,
    saveUserWeekStartDay,
    saveUserTimezone,
    saveUserAllowWeeklyReviewEmails,
    saveUserWeeklyReviewEmailTime,
    saveThemeMode,
    userOnboardingSteps,
    completeOnboardingStep,
    setUserSubscriptionPremium,
    isDarkTheme,
    updateTheme,
  };
});
