import { WeekStartDay } from "@/types/common";
import WeekOfYear from "@/time/WeekOfYear";
import WeeklyGoal from "./WeeklyGoal";
import { CalendarTracking } from "./CalendarTracking";

export type TrackableUnit = "TIMES" | "DURATION" | "DAYS";

export type CalendarTrackingStatus = "SUCCESS" | "ERROR";

export const OTHER_AREA_ID = "OTHER";

export const ACTIVITY_NAME_MAX_LENGTH = 40;
export const ACTIVITY_DESC_MAX_LENGTH = 500;

export const isActivityNameValid = (name: string) => {
  if (!name || name.trim() === "" || name.length > ACTIVITY_NAME_MAX_LENGTH) {
    return false;
  }
  return true;
};

export interface ActivityFields {
  id: string;
  name: string;
  description: string;
  color: string;
  icon: string;
  areaId: string;
  unit: TrackableUnit;
  customTimesUnit: string | undefined;
  weeklyGoalsTimeline: WeeklyGoal[];
  calendarTracking: CalendarTracking;
  calendarTrackingStatus: CalendarTrackingStatus;
  order: number;
  isActive: boolean;
}

export default class Activity {
  id: string;
  name: string;
  description: string;
  color: string;
  icon: string;
  areaId: string;
  unit: TrackableUnit;
  customTimesUnit?: string;
  weeklyGoalsTimeline: WeeklyGoal[];
  calendarTracking: CalendarTracking;
  calendarTrackingStatus: CalendarTrackingStatus;
  order: number;
  isActive: boolean;

  constructor(fields: ActivityFields) {
    this.id = fields.id;
    this.name = fields.name;
    this.description = fields.description;
    this.color = fields.color;
    this.icon = fields.icon;
    this.areaId = fields.areaId;
    this.unit = fields.unit;
    this.customTimesUnit = fields.customTimesUnit;
    this.weeklyGoalsTimeline = fields.weeklyGoalsTimeline;
    this.calendarTracking = fields.calendarTracking;
    this.calendarTrackingStatus = fields.calendarTrackingStatus;
    this.order = fields.order;
    this.isActive = fields.isActive;
  }

  getWeeklyGoalByWeek(week: WeekOfYear): WeeklyGoal | null {
    return this.getWeeklyGoalByDate(week.weekStarts, week.weekStartDay);
  }

  /**
   * Returns the weekly goal that is active on the given week, defined by the date within that week and the week start day.
   */
  getWeeklyGoalByDate(
    date: Date,
    weekStartDay: WeekStartDay,
  ): WeeklyGoal | null {
    const week = new WeekOfYear(date, weekStartDay);

    return (
      this.weeklyGoalsTimeline.find((it) => {
        const startWeek: WeekOfYear = WeekOfYear.fromWeekId(
          it.startWeekId,
          weekStartDay,
        );
        const endWeek: WeekOfYear | null =
          it.endWeekId !== undefined && it.endWeekId !== null
            ? WeekOfYear.fromWeekId(it.endWeekId, weekStartDay).minusWeeks(1)
            : null;
        return week.isWithinWeeksPeriod(startWeek, endWeek);
      }) || null
    );
  }

  getLastWeeklyGoal(): WeeklyGoal | null {
    if (this.weeklyGoalsTimeline.length === 0) {
      return null;
    }
    return this.weeklyGoalsTimeline[this.weeklyGoalsTimeline.length - 1];
  }

  hasWeeklyGoal(week: WeekOfYear): boolean {
    return this.getWeeklyGoalByWeek(week) !== null;
  }
}
