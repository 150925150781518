import WeeklyGoal from "@/store/activities/WeeklyGoal";
import Activity, {
  TrackableUnit,
  CalendarTrackingStatus,
} from "@/store/activities/Activity";
import { CalendarTracking } from "@/store/activities/CalendarTracking";

export interface ActivityDto {
  id: string;
  name: string;
  description: string;
  icon: string;
  color: string;
  areaId: string;
  unit: TrackableUnit;
  customTimesUnit?: string;
  weeklyGoalsTimeline: WeeklyGoal[];
  calendarTracking: CalendarTracking;
  calendarTrackingStatus: CalendarTrackingStatus;
  order: number;
  isActive: boolean;
}

export function activityDtoToActivity(dto: ActivityDto): Activity {
  return new Activity({
    id: dto.id,
    name: dto.name,
    description: dto.description,
    color: dto.color,
    icon: dto.icon,
    areaId: dto.areaId,
    unit: dto.unit,
    customTimesUnit: dto.customTimesUnit,
    weeklyGoalsTimeline: dto.weeklyGoalsTimeline,
    calendarTracking: dto.calendarTracking,
    calendarTrackingStatus: dto.calendarTrackingStatus,
    order: dto.order,
    isActive: dto.isActive,
  });
}
