import api, { getApiError } from "@/api";
import { ActivityAreaAndOrderUpdate } from "@/store/activities/ActivityAreaAndOrderUpdate";
import { CalendarTracking } from "@/store/activities/CalendarTracking";
import WeeklyGoalUpdate from "@/store/activities/WeeklyGoalUpdate";
import CreateActivityData from "@/store/activities/CreateActivityData";
import UpdateActivityData from "@/store/activities/UpdateActivityData";

export async function get() {
  const { data, error } = await api.GET("/api/v1/as");
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function updateActivitiesAreaAndOrder(
  updates: ActivityAreaAndOrderUpdate[],
) {
  const { error } = await api.POST(`/api/v1/as/area-and-order`, {
    body: {
      updates: updates,
    },
  });
  if (error) {
    throw getApiError(error);
  }
}

export async function createActivity(activityData: CreateActivityData) {
  const { data, error } = await api.POST("/api/v1/a", {
    body: activityData,
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function updateActivity(activityData: UpdateActivityData) {
  const { data, error } = await api.PATCH("/api/v1/a", {
    body: activityData,
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function deleteActivity(activityId: string) {
  const { error } = await api.DELETE(`/api/v1/a`, {
    body: { activityId: activityId },
  });
  if (error) {
    throw getApiError(error);
  }
}

export async function updateIsActive(activityId: string, isActive: boolean) {
  const { error } = await api.POST(`/api/v1/a/is-active`, {
    body: { activityId: activityId, isActive: isActive },
  });
  if (error) {
    throw getApiError(error);
  }
}

export async function updateWeeklyGoal(
  activityId: string,
  weeklyGoalUpdate: WeeklyGoalUpdate,
) {
  const { error } = await api.POST(`/api/v1/a/weekly-goal/update`, {
    body: {
      activityId: activityId,
      startWeekId: weeklyGoalUpdate.startWeek.getWeekId(),
      target: weeklyGoalUpdate.target ?? undefined,
    },
  });
  if (error) {
    throw getApiError(error);
  }
}

export async function updateCalendarTracking(
  activityId: string,
  calendarTracking: CalendarTracking,
) {
  const { error } = await api.POST(`/api/v1/a/calendar-tracking/update`, {
    body: {
      activityId: activityId,
      updatedCalendarTracking: calendarTracking,
    },
  });
  if (error) {
    throw getApiError(error);
  }
}
