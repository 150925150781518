import api, { getApiError } from "@/api";
import type { CreateAreaData } from "@/store/areas/CreateAreaData";
import type { UpdateAreaData } from "@/store/areas/UpdateAreaData";
import type { AreaOrderUpdate } from "@/store/areas/AreaOrderUpdate";

export async function getAll() {
  const { data, error } = await api.GET("/api/v1/areas");
  if (error || !data) {
    throw getApiError(error);
  }
  return data.areas;
}

export async function createArea(areaData: CreateAreaData) {
  const { data, error } = await api.POST("/api/v1/area", {
    body: areaData,
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data.createdArea;
}

export async function updateArea(areaData: UpdateAreaData) {
  const { data, error } = await api.PATCH("/api/v1/area", {
    body: areaData,
  });
  if (error || !data) {
    throw getApiError(error);
  }
  return data;
}

export async function deleteArea(areaId: string) {
  const { error } = await api.DELETE("/api/v1/area", {
    body: { id: areaId },
  });
  if (error) {
    throw getApiError(error);
  }
}

export async function updateAreasOrder(updates: AreaOrderUpdate[]) {
  const { error } = await api.POST("/api/v1/areas/order", {
    body: { updates },
  });
  if (error) {
    throw getApiError(error);
  }
}
