import { defineStore } from "pinia";
import Activity from "./Activity";
import * as activitiesApi from "@/api/activities/activitiesApi";
import { computed, reactive } from "vue";
import useInitializableStore from "@/store/initializableStore";
import WeeklyGoalUpdate from "@/store/activities/WeeklyGoalUpdate";
import { ActivityAreaAndOrderUpdate } from "./ActivityAreaAndOrderUpdate";
import { CalendarTracking } from "@/store/activities/CalendarTracking";
import CreateActivityData from "@/store/activities/CreateActivityData";
import UpdateActivityData from "@/store/activities/UpdateActivityData";
import { activityDtoToActivity } from "@/api/activities/dto/ActivityDto";
import { useAreasStore } from "@/store/areas/areasStore";

export const useActivitiesStore = defineStore("activities", () => {
  const areasStore = useAreasStore();
  const activities = reactive<Activity[]>([]);

  const { initializationFailed, initialized, loading, baseInitialize } =
    useInitializableStore();

  async function initialize(force = false): Promise<void> {
    return baseInitialize(internalInit, force);
  }

  async function internalInit() {
    const response = await activitiesApi.get();

    activities.length = 0;
    response.activities.forEach((it) => {
      const activity = activityDtoToActivity(it);
      activities.push(activity);
    });
  }

  const areaIdToActivities = computed<Map<string, Activity[]>>(() => {
    const map = new Map<string, Activity[]>();
    const areaIds = areasStore.areas.map((it) => it.id);
    areaIds.forEach((areaId) => {
      const vals = activities
        .filter((it) => it.areaId == areaId && it.isActive)
        .sort((a, b) => a.order - b.order);
      if (vals.length > 0) {
        map.set(areaId, vals);
      }
    });
    return map;
  });

  const activeActivities = computed<Activity[]>(() => {
    return activities
      .filter((it) => it.isActive)
      .sort((a, b) => a.order - b.order);
  });

  const archivedActivities = computed<Activity[]>(() => {
    return activities
      .filter((it) => !it.isActive)
      .sort((a, b) => a.order - b.order);
  });

  async function deleteActivity(activityId: string): Promise<void> {
    const index = activities.findIndex((it) => it.id === activityId);
    if (index !== -1) {
      activities.splice(index, 1);
    }
    await activitiesApi.deleteActivity(activityId);
  }

  async function createActivity(
    activityData: CreateActivityData,
  ): Promise<Activity | undefined> {
    const response = await activitiesApi.createActivity(activityData);
    await initialize(true);
    return activities.find((it) => it.id === response.activity.id);
  }

  async function updateActivity(activityData: UpdateActivityData) {
    await activitiesApi.updateActivity(activityData);
    await initialize(true);
  }

  async function updateIsActive(
    activityId: string,
    isActive: boolean,
  ): Promise<void> {
    const activity = activities.find((it) => it.id === activityId);
    if (activity) {
      activity.isActive = isActive;
      await activitiesApi.updateIsActive(activityId, isActive);
    }
  }

  async function updateWeeklyGoal(
    activityId: string,
    weeklyGoalUpdate: WeeklyGoalUpdate,
  ): Promise<void> {
    await activitiesApi.updateWeeklyGoal(activityId, weeklyGoalUpdate);
    await initialize(true);
  }

  async function updateCalendarTracking(
    activityId: string,
    calendarTracking: CalendarTracking,
  ) {
    const activity = activities.find((it) => it.id === activityId);
    if (activity) {
      activity.calendarTracking = calendarTracking;
      await activitiesApi.updateCalendarTracking(activityId, calendarTracking);
    }
  }

  async function moveToAreaAndIndex(
    activityId: string,
    newArea: string,
    newIndex: number,
  ) {
    const movingActivity = activities.find((it) => it.id === activityId);
    if (!movingActivity) {
      throw new Error(`Activity with id ${activityId} not found`);
    }

    // Remove the activity from its current area and update its area
    movingActivity.areaId = newArea;

    // Collect all activities in the new area excluding the moving one
    const areaActivities = activities
      .filter((it) => it.areaId === newArea && it.id !== activityId)
      .sort((a, b) => a.order - b.order);

    const reorderedActivities = [
      ...areaActivities.slice(0, newIndex), // Activities before the new index
      movingActivity, // Moving activity
      ...areaActivities.slice(newIndex), // Activities after the new index
    ];

    const updatedOrder: ActivityAreaAndOrderUpdate[] = reorderedActivities.map(
      (activity, index) => ({
        activityId: activity.id,
        areaId: newArea,
        order: index,
      }),
    );
    updatedOrder.forEach((it) => {
      const updatedActivity = activities.find(
        (activity) => activity.id === it.activityId,
      );
      if (!updatedActivity) {
        throw new Error(`Activity with id ${it.activityId} not found`);
      }
      updatedActivity.order = it.order;
      updatedActivity.areaId = it.areaId;
    });

    await activitiesApi.updateActivitiesAreaAndOrder(updatedOrder);
  }

  return {
    activities,
    initializationFailed,
    initialized,
    loading,
    initialize,
    activeActivities,
    archivedActivities,
    createActivity,
    updateActivity,
    deleteActivity,
    updateIsActive,
    updateWeeklyGoal,
    moveToAreaAndIndex,
    areaIdToActivities,
    updateCalendarTracking,
  };
});
