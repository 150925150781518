import { createI18n } from "vue-i18n";
import * as enLocale from "@/locales/en.json";
import * as esLocale from "@/locales/es.json";
import * as jaLocale from "@/locales/ja.json";

const messages = { en: enLocale, es: esLocale, ja: jaLocale };

const instance = createI18n({
  legacy: false,
  locale: "en",
  fallbackLocale: "en",
  messages,
});

export default instance;

export const i18n = instance.global;
