import { defineStore } from "pinia";
import { reactive, computed } from "vue";
import * as areasApi from "@/api/areas/areasApi";
import { Area } from "@/store/areas/area";
import { CreateAreaData } from "@/store/areas/CreateAreaData";
import { UpdateAreaData } from "@/store/areas/UpdateAreaData";
import { AreaOrderUpdate } from "@/store/areas/AreaOrderUpdate";
import useInitializableStore from "@/store/initializableStore";
import { i18n } from "@/i18n";
import { useActivitiesStore } from "@/store/activities/activitiesStore";

export const useAreasStore = defineStore("areas", () => {
  const defaultAreas = computed(() => {
    return [
      {
        id: "HEALTH",
        name: i18n.t("areas.health"),
        color: "#70A8ED",
        order: 0,
      },
      {
        id: "RELATIONSHIPS",
        name: i18n.t("areas.relationships"),
        color: "#62BCAF",
        order: 1,
      },
      { id: "WORK", name: i18n.t("areas.work"), color: "#EAC00F", order: 2 },
      {
        id: "GROWTH",
        name: i18n.t("areas.growth"),
        color: "#F2916B",
        order: 3,
      },
      {
        id: "ENJOYMENT",
        name: i18n.t("areas.enjoyment"),
        color: "#F492AC",
        order: 4,
      },
      { id: "OTHER", name: i18n.t("areas.other"), color: "#B3B3B3", order: 5 },
    ];
  });

  const areas = reactive<Area[]>([]);

  const sortedAreas = computed(() =>
    [...areas].sort((a, b) => a.order - b.order),
  );

  const { initializationFailed, initialized, loading, baseInitialize } =
    useInitializableStore();

  async function initialize(force = false): Promise<void> {
    return baseInitialize(internalInit, force);
  }

  async function internalInit() {
    try {
      loading.value = true;
      const allAreas = await areasApi.getAll();
      areas.splice(0, areas.length, ...allAreas);
    } finally {
      loading.value = false;
    }
  }

  function getAreaById(areaId: string | undefined | null): Area {
    const otherArea: Area = {
      id: "",
      name: "Other",
      color: "#888888",
      order: 0,
    };
    const area = areas.find((area) => area.id === areaId);
    return area || otherArea;
  }

  async function createArea(newArea: CreateAreaData) {
    const createdArea = await areasApi.createArea(newArea);
    areas.push(createdArea);
  }

  async function updateArea(updatedArea: UpdateAreaData) {
    const areaIndex = areas.findIndex((area) => area.id === updatedArea.id);
    if (areaIndex === -1) {
      throw new Error(`Area with id ${updatedArea.id} not found`);
    }

    await areasApi.updateArea(updatedArea);
    Object.assign(areas[areaIndex], updatedArea);
  }

  async function deleteArea(areaId: string) {
    await areasApi.deleteArea(areaId);
    const areaIndex = areas.findIndex((area) => area.id === areaId);
    if (areaIndex === -1) {
      throw new Error(`Area with id ${areaId} not found`);
    }
    areas.splice(areaIndex, 1);
    // Activities might be updated by this action, so we need to reinitialize
    const activitiesStore = useActivitiesStore();
    await activitiesStore.initialize(true);
  }

  async function updateOrder(updates: AreaOrderUpdate[]) {
    // Update local state
    updates.forEach(({ id, order }) => {
      const area = areas.find((area) => area.id === id);
      if (!area) {
        throw new Error(`Area with id ${id} not found`);
      }
      area.order = order;
    });

    // Send to backend
    await areasApi.updateAreasOrder(updates);
  }

  return {
    initialize,
    initialized,
    initializationFailed,
    areas: sortedAreas,
    defaultAreas,
    loading,
    getAreaById,
    createArea,
    updateArea,
    deleteArea,
    updateOrder,
  };
});
